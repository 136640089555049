<template>
  <div>
    <div class="col-grow flex justify-between h-full">
      <div class="table-loading" v-if="loading"></div>
      <e-window-table
          class="col-grow h-full h-limited"
          :columns="['ID', 'Origem', 'Destino', 'Valor', 'Opções']"
      >
        <e-tr v-for="(data, index) in lista" :key="index">
          <e-td><span>{{ String(data.id).padStart(4, '0') }}</span></e-td>
          <e-td>{{ (data.origem || {}).nome || '-' }}</e-td>
          <e-td>{{ (data.destino || {}).nome || '-' }}</e-td>
          <e-td>R$ {{ data.valor|moeda }}</e-td>
          <e-td>
            <a @click="open(data)"><i class="erp-icon search min"></i> </a>
            <!--<u-icon name="times" type="fa" icon-style="regular" class="text-negative text-md m-l cursor-pointer m-b-xs" @click.native="remove(data)"></u-icon>-->
          </e-td>
        </e-tr>
        <e-tr v-if="!loading && (!lista || !lista.length)">
          <e-td style="text-align: left !important;" colspan="100%">
            Nenhum registro
          </e-td>
        </e-tr>
      </e-window-table>
    </div>
    <div class="flex justify-end">
      <u-btn class="m-t-sm" size="sm" @click="adicionaLocal" v-if="enableAdd">Adicionar local</u-btn>
    </div>
  </div>
</template>

<script>
import {
  EWindowTable,
  ETd,
  ETr
} from 'uloc-vue-plugin-erp'

export default {
  name: "TableValoresRemocaoLocalidade",
  props: {
    lista: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    enableAdd: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    EWindowTable,
    ETd,
    ETr
  },
  methods: {
    open (data) {
      console.log(data)
      this.$emit('open', data)
    },
    remove (data) {
      console.log(data)
      this.$emit('remove', data)
    },
    adicionaLocal () {
      this.$emit('adicionaLocal')
    }
  }
}
</script>

<style scoped lang="stylus">
// @import "../../assets/style.css";
.h-limited {
  max-height: 300px;
  overflow: auto;
}
</style>
