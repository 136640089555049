<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper-sm">
      <e-row class="erp-row-col-md">

        <div class="col" style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Código:"
              label-width="90px"
          >
            <erp-input autofocus simple-border v-model="localidade.id" readonly />
          </erp-s-field>
        </div>

        <!--<div class="col" style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Tipo:"
          >
            <erp-select :options="tiposLocalidade" v-model="localidade.tipo" />
          </erp-s-field>
        </div>-->

        <e-col style="max-width: 110px">
          <uf-select field-view="lr" label="UF:" v-model="localidade.ufOld" />
        </e-col>
        <e-col>
          <cidade-select field-view="lr" :uf="localidade.ufOld" label="Cidade:" v-model="localidade.cidade" />
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="90px"
          >
            <erp-input simple-border v-model="localidade.nome" />
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="Endereço:"
          >
            <erp-input simple-border v-model="localidade.endereco" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="erp-row-col-md">
        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Latitude:"
              label-width="90px"
          >
            <erp-input simple-border v-model="localidade.latitude" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Longitude:"
              label-width="90px"
          >
            <erp-input simple-border v-model="localidade.longitude" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Status"
          >
            <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]"
                        v-model="localidade.active" />
          </erp-s-field>
        </e-col>
      </e-row>
      <div class="m-t-lg">
        <table-valores-remocao-localidade :lista="valoresRemocao"></table-valores-remocao-localidade>
      </div>
    </div>

    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Localidade registrada com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  WindowContent,
  // WindowHeader,
  // WindowBody,
  WindowFooter,
  // ErpTabItem,
  // ErpTabs,
  ErpSField,
  ErpInput,
  ErpSelect,
  // ErpCheckbox
  // HelperInputBtn,
  // ErpBox
  // ErpLabel
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../../layout/window/Loading'
import TimerCloseWindow from '../../../../layout/window/TimerClose'
import WindowSuccess from '../../../../layout/window/Success'
import {
  newLocalRemocao,
  updateLocalRemocao,
  findLocalRemocao as find,
  getValores
} from "../../../../../domain/remocao/services";
import UfSelect from '../../../../remocao/components/include/UfSelect'
import CidadeSelect from '../../../../remocao/components/include/CidadeSelect'
import ECol from '../../../../layout/components/Col'
import ERow from '../../../../layout/components/Row'
import TableValoresRemocaoLocalidade from "../../include/tables/TableValoresRemocaoLocalidade";

let mockLocalidadeRemocao = {
  id: null,
  nome: null,
  endereco: null,
  tipo: 0,
  latitude: null,
  longitude: null,
  cidade: null,
  cidadeOldId: null,
  ufOld: null
}

export default {
  name: 'LocalidadeRemocaoWindow',
  props: ['id'],
  components: {
    TableValoresRemocaoLocalidade,
    ERow,
    ECol,
    CidadeSelect,
    UfSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    // ErpTabs,
    // ErpTabItem,
    ErpSField,
    ErpInput,
    ErpSelect
    // ErpCheckbox
    // HelperInputBtn,
    // ErpLabel
  },
  data () {
    return {
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      localidade: JSON.parse(JSON.stringify(mockLocalidadeRemocao)),
      tiposLocalidade: [
        {label: 'Tipo 1', value: 1},
        {label: 'Tipo 2', value: 2}
      ],
      valoresRemocao: []
    }
  },
  mounted () {
    this.id && this.load()
    this.id && this.loadValoresRemocao()
  },
  computed: {},
  methods: {
    load () {
      this.loading = true
      find(this.id || this.localidade.id)
          .then(response => {
            let data = response.data
            console.log(data)

            /* if (data.tipo && data.tipo.id) {
              data.tipo = data.tipo.id
            }

            if (data.cidade && data.cidadeOId) {
              data.cidade = data.cidadeOldId
            } */
            data.cidade = data.cidadeOldId
            data.uf = data.ufOld
            data.status = 1 // temp

            this.localidade = data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar os dados, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    loadValoresRemocao () {
      getValores(this.id)
          .then(response => {
            this.valoresRemocao = response.data?.result || response.data
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar os valores, entre em contato com o administrador.`
            })
            console.log(error)
          })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.localidade))

      if (data.cidade && data.cidade.id) {
        data.cidadeOldId = data.cidade.id
        data.cidadeOld = data.cidade.nome
      }

      /* if (data.uf && data.uf.id) { // Not work
        data.ufOldId = data.uf.id
        data.ufOld = data.uf.nome
      } */

      this.status = 'Processando dados do local de remoção'
      let method = data.id ? updateLocalRemocao(data.id, data) : newLocalRemocao(data)
      method
          .then(({data}) => {
            this.localidade.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                // this.$refs.timer.start()
                this.load()
                window.setTimeout(() => {
                  this.loading = false
                  this.success = false
                }, 3000)
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    }
  }
}
</script>
